<template>
  <div>
    <div class="misc-wrapper">
      <b-link class="brand-logo">
        <logo />
      </b-link>

      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">Yetkiniz yok. 🔐</h2>
          <p class="mb-2">Bu sayfayaya erişmek için yeterli yetkiye sahip değilsin.</p>
          <b-button variant="primary" class="mb-1 mr-25 btn-sm-block" :to="{ name: 'dashboard' }"> Ana Sayfa'ya Dön </b-button>
          <b-button @click="logout" variant="primary" class="mb-1 btn-sm-block"> Çıkış Yap </b-button>

          <b-img fluid :src="imgUrl" alt="Not authorized page" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import logo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";

export default {
  components: {
    logo,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/not-authorized.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/not-authorized-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout", { sendLogoutReq: false });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
